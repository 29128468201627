const titles = {
    '/': 'Startseite',
    '/reports': 'Berichtsverwaltung',
    '/reports/edit/:reportId/step-1': 'Bericht bearbeiten - Schritt 1: Bestimmung des Prüfkatalogs',
    '/reports/edit/:reportId/step-2': 'Bericht bearbeiten - Schritt 2: Prüfungsdaten',
    '/reports/edit/:reportId/step-3': 'Bericht bearbeiten - Schritt 3: Bewertung der Anforderungen',
    '/reports/edit/:reportId/step-4': 'Bericht bearbeiten - Schritt 4: Dokumente',
    '/reports/edit/:reportId/step-5': 'Bericht bearbeiten - Schritt 5: Validierungsergebnis',
    '/reports/import': 'Bericht importieren',
    '/reports/create': 'Bericht erfassen',
    '/analysis': 'Auswertungen',
    '/administration': 'Administration',
    '/logs': 'Protokollierung',
    '/impressum': 'Impressum',
    '/privacy': 'Datenschutzerklärung',
    '/accessibility': 'Erklärung zur Barrierefreiheit',
};

export default titles;
