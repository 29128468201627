import { initReactI18next, useTranslation } from "react-i18next";
import i18next, { TFunction } from "i18next";
import { Language } from "@/_apiSchema.ts";
import DE from "@/core/locales/de/default.json";
import DE_API from "@/core/locales/de/api.json";

export enum LOCALE_NS {
    DEFAULT = "default",
    API = "api",
}

export type DefaultTranslation = TFunction<LOCALE_NS.DEFAULT, undefined>;
export type ApiTranslation = TFunction<LOCALE_NS.API, undefined>;

const resources = {
    [Language.de]: {
        [LOCALE_NS.DEFAULT]: DE,
        [LOCALE_NS.API]: DE_API,
    },
} as const;

/**
 * Initialized i18n instance
 * @see: https://www.i18next.com/overview/configuration-options
 */
export function initI18n(lang = Language.de) {
    return i18next.use(initReactI18next).init({
        resources,

        lng: lang, // if you're using a language detector, do not define the lng option
        fallbackLng: lang,

        defaultNS: LOCALE_NS.DEFAULT,
        ns: [LOCALE_NS.DEFAULT, LOCALE_NS.API],

        interpolation: {
            escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
        },
    });
}

/**
 * Custom hook to use translations for api namespace
 */
export function useApiTranslation() {
    return useTranslation(LOCALE_NS.API);
}

// re-export function for consistent usage
export { useTranslation } from "react-i18next";
