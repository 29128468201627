import { FC } from "react";
import { KolNav } from "@public-ui/react";
import { Orientation } from "@public-ui/components";
import { headerRoutes } from "../../router/headerRoutes.ts";
import { useRoutedKolibriLinks } from "@/lib/kolibri/utils/kolibri.ts";
import { usePermittedRoutes } from "@/core/hooks/route.ts";

interface IHeaderNavbarProps {
    /**
     * Orientation of navbar
     */
    orientation: Orientation;
    onNavigation: () => void;
}

export const HeaderNavbar: FC<IHeaderNavbarProps> = ({ orientation, onNavigation }) => {
    const visibleRoutes = usePermittedRoutes(headerRoutes);
    const links = useRoutedKolibriLinks(visibleRoutes || [], {}, onNavigation);
    if (links.length === 0) {
        return null;
    }
    return <KolNav _label="Navigation" className="header-navbar" _links={links} _orientation={orientation}/>;
};
