import { useQuery } from "@tanstack/react-query";
import { getUser } from "@/core/api/endpoints/user.ts";
import { buildUser } from "@/core/api/model/user.ts";
import { showError } from "@/lib/kolibri/utils/notification.ts";

export const USER_QUERY_KEY = "userInfo";

/**
 * Custom hook to get user info data
 */
export function useUserQuery() {
    return useQuery({
        queryKey: [USER_QUERY_KEY],
        queryFn: getUser,
        select: buildUser,
        retry: false,
        throwOnError: (error) => {
            console.error("useUserQuery", error);
            showError(error.message, "Serverfehler");
            return false;
        },
    });
}
