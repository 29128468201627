import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App.tsx";

import { register } from "@public-ui/components";
import { defineCustomElements } from "@public-ui/components/dist/loader";
import { customTheme } from "./_theme.ts";
import { initAuth } from "@/lib/auth/controller.ts";

import "./index.scss";
import { initI18n } from "@/lib/i18n/index.ts";
import { AppProvider } from "@/core/provider/AppProvider.tsx";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

initAuth()
    .then(async () => {
        await register(customTheme, defineCustomElements);
        await initI18n();

        const queryClient = new QueryClient();

        createRoot(document.getElementById("root") as HTMLElement).render(
            <React.StrictMode>
                <QueryClientProvider client={queryClient}>
                    <AppProvider>
                        <App />
                    </AppProvider>
                </QueryClientProvider>
            </React.StrictMode>,
        );
    })
    .catch(console.error);
