import { createContext, useState, useEffect, useContext, ReactNode, FC } from "react";

interface TitleContextType {
    setTitle: (title: string) => void;
}

const TitleContext = createContext<TitleContextType | undefined>(undefined);

interface TitleProviderProps {
    children: ReactNode;
}

const DEFAULT_TITLE = 'BFIT-Reporting' as const;
export const TitleProvider: FC<TitleProviderProps> = ({ children }) => {
    const [title, setTitle] = useState<string>(DEFAULT_TITLE);

    useEffect(() => {
        document.title = `${title} - ${DEFAULT_TITLE}` || DEFAULT_TITLE;
    }, [title]);

    return (
        <TitleContext.Provider value={{ setTitle }}>
            {children}
        </TitleContext.Provider>
    );
};

export function useTitle(): (title: string) => void {
    const context = useContext(TitleContext);
    if (context === undefined) {
        throw new Error('useTitle muss innerhalb eines TitleProvider verwendet werden');
    }
    return context.setTitle;
}
