import { ToasterService } from "@public-ui/components";
import { AlertType } from "@public-ui/schema";

let _toaster: ToasterService;

/**
 * Initializes toaster service instance
 */
function init() {
    if (_toaster) {
        return;
    }
    _toaster = ToasterService.getInstance(document);
}

/**
 * Displays notification card
 */
function showNotification(text: string, title: string, type: AlertType) {
    init();

    _toaster.enqueue({
        label: title,
        description: text,
        type: type,
        alertVariant: "card",
    });
}

/**
 * Displays an error message popup
 */
export function showError(text: string, title = "Fehler") {
    showNotification(text, title, "error");
}

/**
 * Displays an error message popup
 */
export function showSuccess(text: string, title = "Erfolgsmeldung") {
    showNotification(text, title, "success");
}
