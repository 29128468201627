import { TRoute } from "@/core/types/TRoute.ts";

export const footerRoutes: TRoute[] = [
    {
        path: "/impressum",
        title: "Impressum",
    },
    {
        path: "/privacy",
        title: "Datenschutzvereinbarung",
    },
    {
        path: "/accessibility",
        title: "Erklärung zur Barrierefreiheit",
    },
];
