import { FC } from "react";
import "./BaseFooter.scss";
import { FooterLinks } from "../../components/FooterLinks/FooterLinks.tsx";

export const BaseFooter: FC = () => {
    return (
        <footer>
            <div className="container-fluid g-0">
                <div className="row justify-content-between">
                    <div className="col-xl-auto col-sm-18 order-xl-last ">
                        <FooterLinks />
                    </div>
                    <div className="col-xl-auto col-sm-18 order-xl-first">
                        <div className="copyright">&copy; 2023 Deutsche Rentenversicherung Knappschaft-Bahn-See</div>
                    </div>
                </div>
            </div>
        </footer>
    );
};
