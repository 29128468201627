import { RouterProvider } from "react-router-dom";
import { setLocale } from "yup";
import { toLocalDate } from "@/core/utils/date.ts";
import { appRouter } from "@/core/router/appRouter.tsx";
import { TitleProvider } from "@/core/context/TitleContext.tsx";

setLocale({
    mixed: {
        required: "Bitte dieses Pflichtfeld ausfüllen.",
        oneOf: "Bitte wählen Sie einen Eintrag aus",
    },
    date: {
        min: ({ min }) => `Das Datum muss nach dem ${toLocalDate(new Date(min))} liegen.`,
        max: ({ max }) => `Das Datum muss vor dem ${toLocalDate(new Date(max))} liegen.`,
    },
});

function App() {

    return (
        <TitleProvider>
            <RouterProvider router={appRouter} />
        </TitleProvider>
    );
}

export default App;
