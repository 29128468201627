import { useLocation } from "react-router-dom";
import { TRoute } from "@/core/types/TRoute.ts";
import { useUser } from "@/core/context/AppContext.ts";

/**
 * Custom hook to get information related to current route in given list of routes
 */
export function useRouteInfo(routes: TRoute[]) {
    const location = useLocation();
    const routeIndex = routes.findIndex((route) => location.pathname.endsWith("/" + route.path));
    const currentRoute = routes[routeIndex] || null;
    const previousRoute = routeIndex > 0 ? routes[routeIndex - 1] : null;
    const nextRoute = routeIndex < routes.length - 1 ? routes[routeIndex + 1] : null;

    return {
        index: routeIndex,
        current: currentRoute,
        previous: previousRoute,
        next: nextRoute,
    };
}

/**
 * Custom hook to filter given routes by permission level. Returns `null` if no active user is available.
 */
export function usePermittedRoutes(routes: TRoute[]) {
    const user = useUser();
    if (!user) {
        return null;
    }
    return routes.filter((route) => !route.restrictions || route.restrictions.includes(user.permissionLevel));
}
