import { FC } from "react";
import "./HeaderTitle.scss";

export const HeaderTitle: FC = () => {
    return (
        <div className="header-title">
            <div className="title">BFIT-Bund Reporting Tool</div>
            <div className="subtitle">Digitale Barrierefreiheit</div>
        </div>
    );
};
