import { warnOnFalse } from "@/core/utils/log.ts";

/**
 * Returns `true` is given array is matching callback for each item
 */
export function isArrayOf<T>(array: unknown, fn: (item: unknown) => boolean, message?: string): array is T[] {
    return warnOnFalse(() => {
        if (!Array.isArray(array)) return false;
        if (array.length === 0) return true;
        return array.every((item) => fn(item));
    }, message);
}
