import { external, InspectionAuthority, PermissionLevel } from "@/_apiSchema.ts";
import { isObjectOf } from "@/core/utils/objects.ts";
import { isArrayOf } from "@/core/utils/array.ts";
import { isString } from "@/core/utils/types.ts";

export interface IUser {
    id: string;
    givenName: string;
    surname: string;
    displayName: string;
    permissionLevel: PermissionLevel;
    inspectionAuthorities: InspectionAuthority[];
}

export type TUserDTO = external["components/schemas/commons.yaml"]["components"]["schemas"]["UserDTO"];

/**
 * Builds a user info object based on api contents
 */
export function buildUser(content: TUserDTO): IUser {
    if (!content.permissionLevel) {
        throw new Error("Missing or invalid user role.");
    }

    return {
        id: content.id || "",
        givenName: content.givenName || "",
        surname: content.surname || "",
        displayName: `${content.givenName} ${content.surname}`,
        permissionLevel: content.permissionLevel,
        inspectionAuthorities: content.inspectionAuthorities || [],
    };
}

/**
 * Returns `true` if content is of type `UserDTO`
 */
export function isUserDTO(content: unknown): content is TUserDTO {
    return isObjectOf<TUserDTO>(content, {
        message: "Content is not of type TUserDTO",
        allOf: ["id", "givenName", "surname", "permissionLevel", "inspectionAuthorities"],
        fn: (object) =>
            isArrayOf<InspectionAuthority>(
                object.inspectionAuthorities,
                (entry) => isString(entry) && entry in InspectionAuthority,
                "Content is not array of type InspectionAuthority",
            ),
    });
}
