import { FC } from "react";
import { KolButton } from "@public-ui/react";

interface IMenuButtonProps {
    /**
     * Label of button
     */
    label: string;
    /**
     * Label of button in active state
     */
    closeLabel: string;
    /**
     * Force active pressed state
     */
    expanded?: boolean;
    /**
     * Callback function for OnClick-Event
     */
    onClick: (event: Event) => void;
    /**
     * Target control id for expanded description
     */
    expandControl?: string;
}

export const MenuButton: FC<IMenuButtonProps> = ({ label, closeLabel, expanded, onClick, expandControl }) => {
    return (
        <KolButton
            _label={expanded ? closeLabel : label}
            _hideLabel
            _icons={expanded ? "icon icon-times-white" : "icon icon-bars-white"}
            _variant="primary"
            _on={{ onClick }}
            _ariaExpanded={expanded}
            _ariaControls={expandControl}
        />
    );
};
