/**
 * Returns `true` if given value is valid number
 */
export function isNumber(value: unknown): value is number {
    return typeof value === "number" && !isNaN(value);
}

/**
 * Returns `true` if given value is boolean
 */
export function isBoolean(value: unknown): value is boolean {
    return typeof value === "boolean";
}

/**
 * Returns `true` if given value is string
 */
export function isString(value: unknown): value is string {
    return typeof value === "string";
}

/**
 * Returns `true` if given value is a valid object
 */
export function isObject(value: unknown): value is object {
    return value !== null && typeof value === "object";
}
