import { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import titles from '@/core/router/titleConfig.ts';
import { useTitle } from '@/core/context/TitleContext.tsx';

function useDynamicTitle() {
    const location = useLocation();
    const params = useParams();
    const setTitle = useTitle();

    useEffect(() => {
        const pathKeys = Object.keys(titles);
        const currentPath = pathKeys.find(path =>
            // regex to match the path with the current location (basically to ignore the query params)
            new RegExp("^" + path.replace(/:\w+/g, "[\\w-]+") + "$").test(location.pathname)
        );
        if (currentPath) {
            setTitle(titles[currentPath] || '');
        }
    }, [location, params, setTitle]);
}

export default useDynamicTitle;
