import { FC } from "react";
import { LogoutButton } from "../LogoutButton/LogoutButton.tsx";

import "./HeaderLogin.css";
import { useUser } from "@/core/context/AppContext.ts";

interface IHeaderLoginProps {
    /**
     * Orientation of contents
     */
    orientation?: "horizontal" | "vertical";
    /**
     * Alignment of contents
     */
    align?: "left" | "right";
}

export const HeaderLogin: FC<IHeaderLoginProps> = ({ orientation = "horizontal", align = "left" }) => {
    const user = useUser();

    const isHorizontal = orientation === "horizontal";
    const isLeftAligned = align === "left";

    const classes = ["d-flex"];
    if (isHorizontal) {
        classes.push("align-items-center");
    } else {
        classes.push("flex-column");
    }
    if (!isLeftAligned) {
        classes.push("justify-content-end");
    }
    if (isLeftAligned && !isHorizontal) {
        classes.push("align-items-start");
    }
    if (!isLeftAligned && !isHorizontal) {
        classes.push("align-items-end");
    }

    const usernamePadding = isHorizontal ? "pe-4" : "pb-2";

    return (
        <div className={classes.join(" ")}>
            <div className={`username ${usernamePadding}`}>{user ? user.displayName : null}</div>
            <LogoutButton />
        </div>
    );
};
