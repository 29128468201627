import { ChangeEvent, FocusEvent, MouseEvent, ReactNode, useEffect, useState } from "react";
import { InputTypeOnDefault } from "@public-ui/components";

import { useNavigate } from "react-router-dom";
import { TRoute } from "@/core/types/TRoute.ts";
import { createRoot, Root } from "react-dom/client";
import { sortAsc } from "@/core/utils/sort.ts";

const BASE_URL = import.meta.env.VITE_BASE_URL;
const REACT18_ROOTS = new WeakMap<Element | DocumentFragment, Root>();

/**
 * Returns given element as react root element for custom rendering
 */
function getRoot(el: Element | DocumentFragment): Root {
    if (!REACT18_ROOTS.has(el)) {
        REACT18_ROOTS.set(el, createRoot(el));
    }
    return REACT18_ROOTS.get(el) as Root;
}

/**
 * Helper method to bridge an OnChange-Event between KoliBri any form handling framework
 */
export function toKolibriChangeEvent<T = Element, V = unknown | unknown[]>(
    onChangeHandler: (event: ChangeEvent<T>, value?: V) => void,
): InputTypeOnDefault["onChange"] {
    return (kolibriEvent: Event | ChangeEvent<T>, value?: V) => {
        const _event = kolibriEvent as ChangeEvent<T>;
        if (!_event.target) {
            return;
        }
        return onChangeHandler(_event, value as V);
    };
}

/**
 * Helper method to bridge an OnBlur-Event between KoliBri any form handling framework
 */
export function toKolibriBlurEvent<T = Element>(
    onBlurHandler: (event: FocusEvent<T>) => void,
): InputTypeOnDefault["onBlur"] {
    return (kolibriEvent: Event | FocusEvent<T>) => {
        const _event = kolibriEvent as FocusEvent<T>;
        if (!_event.target) {
            return;
        }
        return onBlurHandler(_event);
    };
}

/**
 * Helper method to bridge an OnClick-Event between KoliBri any form handling framework
 */
export function toKolibriClickEvent<T = Element>(
    onChangeHandler: (event: MouseEvent<T>) => void,
): InputTypeOnDefault["onClick"] {
    return (kolibriEvent: Event | MouseEvent<T>) => {
        const _event = kolibriEvent as MouseEvent<T>;
        if (!_event.target) {
            return;
        }
        return onChangeHandler(_event);
    };
}

/**
 * Custom hook for dynamic error message handling.
 * Handles touched state of KoliBri-Input in case of changes to incoming error message.
 */
export function useErrorMessage(error: string | undefined, setTouched: (touched: boolean) => void): string | undefined {
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

    useEffect(() => {
        if (error !== errorMessage) {
            setErrorMessage(error);
            if (!error) setTouched(false);
        }
    }, [error, errorMessage, setTouched]);

    return errorMessage;
}

/**
 * Custom Hook for Dynamic Link Handling.
 * Handles Routing for KoliBri-Nav and Kolibri-link components
 */

export interface RouteDelegate<T = TRoute> {
    /**
     * set path for kolibri-Nav or kolibri-link
     */
    path?: (route: T) => string;
    /**
     * set title for kolibri-Nav or kolibri-link
     */
    title?: (route: T) => string | undefined;
}

export function useRoutedKolibriLinks(
    routes: TRoute[],
    routeDelegate: RouteDelegate = {},
    onNavigation: () => void = () => {
        /*noop*/
    },
) {
    const { title = (route: TRoute) => route.title, path = (route: TRoute) => route.path } = routeDelegate;
    const navigate = useNavigate();

    return routes.map((route) => ({
        _label: title(route),
        _href: path(route).replace(/^\//, BASE_URL), // prepend absolute paths with base url for hard links only to ensure new tabs open with optional context path
        _on: {
            onClick: () => {
                onNavigation();
                navigate(path(route));
            },
        },
    }));
}

/**
 * Renders a React element directly to a DOM-Element
 */
export function renderReactNodeToElement(el: HTMLElement, children: ReactNode) {
    getRoot(el).render(children);
}

/**
 * HOTFIX: BFITRTOOL-385
 * Helper method to convert generic types for kol-table data-functions (render, sort, etc.) to own input type
 */
export function toTableDataType<T>(data: unknown) {
    return data as T;
}

/**
 * Returns callback function for table sort property
 */
export function buildTableSorter<T>(compare: (frist: T, second: T) => number): <T>(data: T[]) => T[] {
    return (data) => {
        const tableData = toTableDataType<T[]>(data);
        const sorted = tableData.sort(compare);
        return toTableDataType<typeof data>(sorted);
    };
}

/**
 * Returns a table sorter for ascending sort order for data by given key
 */
export function buildAscTableSorter<T>(key: keyof T) {
    return buildTableSorter<T>(sortAsc(key));
}
