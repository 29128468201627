import { Language, paths } from "@/_apiSchema.ts";
import { getAccessToken } from "@/lib/auth/controller.ts";
import createClient, { Middleware } from "openapi-fetch";

export const API_BASE_URL = import.meta.env.VITE_API_BASE_URL;

const client = createClient<paths>({
    baseUrl: API_BASE_URL,
    headers: {
        "Content-type": "application/json; charset=UTF-8",
        "BFITRTool-Language": Language.de,
    },
});

const authMiddleware: Middleware = {
    async onRequest(request) {
        const accessToken = await getAccessToken();
        if (!accessToken) {
            throw new Error("Failed to access token");
        }
        // add Authorization header to every request
        request.headers.set("Authorization", `Bearer ${accessToken}`);
        return request;
    },
};

client.use(authMiddleware);

export const { GET, PUT, DELETE, POST } = client;
