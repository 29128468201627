import "./HeaderLogo.scss";
import { FC } from "react";
import { KolImage } from "@public-ui/react";
import { Link } from "react-router-dom";
import homepageLogo from "../../images/logo_bfit.svg";

export const HeaderLogo: FC = () => {
    return (
        <div className="page-logo" >
            <Link to="/" title="Zur Startseite" aria-label="Überwachungsstelle des Bundes für Barrierefreiheit und Informationstechnik zur Startseite">
                <KolImage _alt="Logo BFIT - zur Startseite" _src={homepageLogo} aria-hidden="true" />
            </Link>
        </div>
    );
};
