/**
 * This file is auto generated.
 * DO NOT EDIT, DELETE OR COMMIT THIS FILE.
 * ONLY USE AS-IS.
 */

import { KoliBri } from "@public-ui/schema";

import ABBR from "@/lib/kolibri/theme/bfit/components/abbr.scss?inline";
import ACCORDION from "@/lib/kolibri/theme/bfit/components/accordion.scss?inline";
import ALERT from "@/lib/kolibri/theme/bfit/components/alert.scss?inline";
import BADGE from "@/lib/kolibri/theme/bfit/components/badge.scss?inline";
import BREADCRUMB from "@/lib/kolibri/theme/bfit/components/breadcrumb.scss?inline";
import BUTTON_GROUP from "@/lib/kolibri/theme/bfit/components/button-group.scss?inline";
import BUTTON_LINK from "@/lib/kolibri/theme/bfit/components/button-link.scss?inline";
import BUTTON from "@/lib/kolibri/theme/bfit/components/button.scss?inline";
import CARD from "@/lib/kolibri/theme/bfit/components/card.scss?inline";
import DETAILS from "@/lib/kolibri/theme/bfit/components/details.scss?inline";
import HEADING from "@/lib/kolibri/theme/bfit/components/heading.scss?inline";
import ICON from "@/lib/kolibri/theme/bfit/components/icon.scss?inline";
import IMAGE from "@/lib/kolibri/theme/bfit/components/image.scss?inline";
import INDENTED_TEXT from "@/lib/kolibri/theme/bfit/components/indented-text.scss?inline";
import INPUT_CHECKBOX from "@/lib/kolibri/theme/bfit/components/input-checkbox.scss?inline";
import INPUT_COLOR from "@/lib/kolibri/theme/bfit/components/input-color.scss?inline";
import INPUT_DATE from "@/lib/kolibri/theme/bfit/components/input-date.scss?inline";
import INPUT_EMAIL from "@/lib/kolibri/theme/bfit/components/input-email.scss?inline";
import INPUT_FILE from "@/lib/kolibri/theme/bfit/components/input-file.scss?inline";
import INPUT_NUMBER from "@/lib/kolibri/theme/bfit/components/input-number.scss?inline";
import INPUT_PASSWORD from "@/lib/kolibri/theme/bfit/components/input-password.scss?inline";
import INPUT_RADIO from "@/lib/kolibri/theme/bfit/components/input-radio.scss?inline";
import INPUT_RANGE from "@/lib/kolibri/theme/bfit/components/input-range.scss?inline";
import INPUT_TEXT from "@/lib/kolibri/theme/bfit/components/input-text.scss?inline";
import LINK_BUTTON from "@/lib/kolibri/theme/bfit/components/link-button.scss?inline";
import LINK from "@/lib/kolibri/theme/bfit/components/link.scss?inline";
import MODAL from "@/lib/kolibri/theme/bfit/components/modal.scss?inline";
import NAV from "@/lib/kolibri/theme/bfit/components/nav.scss?inline";
import PAGINATION from "@/lib/kolibri/theme/bfit/components/pagination.scss?inline";
import PROGRESS from "@/lib/kolibri/theme/bfit/components/progress.scss?inline";
import SELECT from "@/lib/kolibri/theme/bfit/components/select.scss?inline";
import SKIP_NAV from "@/lib/kolibri/theme/bfit/components/skip-nav.scss?inline";
import SPIN from "@/lib/kolibri/theme/bfit/components/spin.scss?inline";
import SPLIT_BUTTON from "@/lib/kolibri/theme/bfit/components/split-button.scss?inline";
import TABLE_STATEFUL from "@/lib/kolibri/theme/bfit/components/table-stateful.scss?inline";
import TABLE_STATELESS from "@/lib/kolibri/theme/bfit/components/table-stateless.scss?inline";
import TABLE from "@/lib/kolibri/theme/bfit/components/table.scss?inline";
import TABS from "@/lib/kolibri/theme/bfit/components/tabs.scss?inline";
import TEXTAREA from "@/lib/kolibri/theme/bfit/components/textarea.scss?inline";
import TOAST_CONTAINER from "@/lib/kolibri/theme/bfit/components/toast-container.scss?inline";
import GLOBAL from "@/lib/kolibri/theme/bfit/global.scss?inline";

export const CUSTOM_THEME_NAME = "bfit";

export const customTheme = KoliBri.createTheme(CUSTOM_THEME_NAME, {
    "KOL-ABBR": ABBR,
    "KOL-ACCORDION": ACCORDION,
    "KOL-ALERT": ALERT,
    "KOL-BADGE": BADGE,
    "KOL-BREADCRUMB": BREADCRUMB,
    "KOL-BUTTON-GROUP": BUTTON_GROUP,
    "KOL-BUTTON-LINK": BUTTON_LINK,
    "KOL-BUTTON": BUTTON,
    "KOL-CARD": CARD,
    "KOL-DETAILS": DETAILS,
    "KOL-HEADING": HEADING,
    "KOL-ICON": ICON,
    "KOL-IMAGE": IMAGE,
    "KOL-INDENTED-TEXT": INDENTED_TEXT,
    "KOL-INPUT-CHECKBOX": INPUT_CHECKBOX,
    "KOL-INPUT-COLOR": INPUT_COLOR,
    "KOL-INPUT-DATE": INPUT_DATE,
    "KOL-INPUT-EMAIL": INPUT_EMAIL,
    "KOL-INPUT-FILE": INPUT_FILE,
    "KOL-INPUT-NUMBER": INPUT_NUMBER,
    "KOL-INPUT-PASSWORD": INPUT_PASSWORD,
    "KOL-INPUT-RADIO": INPUT_RADIO,
    "KOL-INPUT-RANGE": INPUT_RANGE,
    "KOL-INPUT-TEXT": INPUT_TEXT,
    "KOL-LINK-BUTTON": LINK_BUTTON,
    "KOL-LINK": LINK,
    "KOL-MODAL": MODAL,
    "KOL-NAV": NAV,
    "KOL-PAGINATION": PAGINATION,
    "KOL-PROGRESS": PROGRESS,
    "KOL-SELECT": SELECT,
    "KOL-SKIP-NAV": SKIP_NAV,
    "KOL-SPIN": SPIN,
    "KOL-SPLIT-BUTTON": SPLIT_BUTTON,
    "KOL-TABLE-STATEFUL": TABLE_STATEFUL,
    "KOL-TABLE-STATELESS": TABLE_STATELESS,
    "KOL-TABLE": TABLE,
    "KOL-TABS": TABS,
    "KOL-TEXTAREA": TEXTAREA,
    "KOL-TOAST-CONTAINER": TOAST_CONTAINER,
    "GLOBAL": GLOBAL,
});
