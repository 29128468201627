import { Iso8601 } from "@public-ui/components";
import { isString } from "@/core/utils/types.ts";

export const DATE_TODAY: Iso8601 = toIsoDate(new Date());

/**
 * Returns formatted date string in ISO-8601 format, e.g. 2050-12-01
 */
export function toIsoDate(date: Date): Iso8601 {
    const year = date.getFullYear();
    const month = `${date.getMonth() + 1}`.padStart(2, "0");
    const day = `${date.getDate()}`.padStart(2, "0");

    return `${year}-${month}-${day}` as Iso8601;
}

/**
 * Returns `true` if given date string is a valid iso date string
 */
export function isIsoDate(date: unknown): date is Iso8601 {
    return isString(date) && /\d\d\d\d-\d\d-\d\d/.test(date);
}

/**
 * Returns formatted date string in current locale, e.g. 01.01.2050 for germany.
 */
export function toLocalDate(date: Date, locales?: string | string[]) {
    return Intl.DateTimeFormat(locales, {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
    }).format(date);
}
