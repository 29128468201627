import { IPublicClientApplication, PublicClientApplication } from "@azure/msal-browser";
import { loginRequest, msalConfig } from "./config.ts";

export const msalInstance = new PublicClientApplication(msalConfig);

/**
 * Returns `true` if login via MSAL is active
 */
export function isLoginEnabled() {
    return import.meta.env.VITE_MSAL_LOGIN_ENABLED === "1";
}

/**
 * Initializes authentication when login is enabled
 * @throws Error
 */
export async function initAuth() {
    if (!isLoginEnabled()) {
        return null;
    }

    return await initMsalInstance().catch((error) => {
        throw new Error(`failed to init authentication:\n${error.message}`);
    });
}

/**
 * Initializes and returns an MSAL instance
 */
export async function initMsalInstance() {
    await msalInstance.initialize();

    await msalInstance
        .handleRedirectPromise()
        .then(async (response) => {
            if (!response) {
                await msalInstance.loginRedirect(loginRequest);
                return;
            }
            msalInstance.setActiveAccount(response.account);
        })
        .catch((error) => {
            throw new Error(`Failed to handle redirect:\n${error.message}`);
        });
    return msalInstance;
}

/**
 * Returns access token for api calls
 * @see {@link https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/hooks.md#usemsal-hook}
 */
export async function getAccessToken() {
    if (!isLoginEnabled()) {
        return "DEMO";
    }
    const account = msalInstance.getActiveAccount();
    if (!account) {
        return null;
    }

    return await msalInstance
        .acquireTokenSilent({
            account: account,
            scopes: loginRequest.scopes,
        })
        .then((response) => response.accessToken)
        .catch(async (error) => {
            throw new Error(`Failed to retrieve token:${error.message}`);
        });
}

/**
 * Performs a sign-out from current authentication
 */
export async function performLogout(homeAccountId: string, instance: IPublicClientApplication) {
    const logoutRequest = {
        account: instance.getAccountByHomeId(homeAccountId),
        postLogoutRedirectUri: msalConfig.auth.postLogoutRedirectUri,
    };
    await instance
        .logoutRedirect(logoutRequest)
        .then(() => console.log("Logout performed."))
        .catch((e) => console.error("Failed to logout", e.message));
}
