import { FC } from "react";
import { KolImage } from "@public-ui/react";
import { useApiTranslation } from "@/lib/i18n/index.ts";
import { InspectionAuthority } from "@/_apiSchema.ts";

const BASE_URL = import.meta.env.VITE_BASE_URL;

export type TWappenCode = Exclude<InspectionAuthority, InspectionAuthority.BUND>;

interface IBaseImageProps {
    /**
     * Code of state
     */
    code: TWappenCode;
}

export const WappenImage: FC<IBaseImageProps> = ({ code }) => {
    const { t } = useApiTranslation();

    return <KolImage _src={`${BASE_URL}assets/states/${code}.svg`} _alt={`Wappen ${t(code)}`} />;
};
