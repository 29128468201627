import { FC } from "react";
import { useUser } from "@/core/context/AppContext.ts";
import { WappenImage } from "../WappenImage/WappenImage.tsx";
import { InspectionAuthority } from "@/_apiSchema.ts";

export const HeaderWappen: FC = () => {
    const user = useUser();

    if (
        !user ||
        user.inspectionAuthorities.length !== 1 ||
        user.inspectionAuthorities[0] === InspectionAuthority.BUND
    ) {
        return null;
    }

    return <WappenImage code={user.inspectionAuthorities[0]} />;
};
