import { useState, useEffect } from "react";

/**
 * Custom hook for matching media queries.
 *
 * @example
 * // check if current device matches following media query
 * const isMediaSM = useMediaQuery("(min-width: 576px)");
 */
export const useMediaQuery = (query: string) => {
    const [matches, setMatches] = useState(false);

    useEffect(() => {
        const media = window.matchMedia(query);
        if (media.matches !== matches) {
            setMatches(media.matches);
        }
        const listener = () => setMatches(media.matches);
        window.addEventListener("resize", listener);
        return () => window.removeEventListener("resize", listener);
    }, [matches, query]);

    return matches;
};

/**
 * Custom hook as shortcut to check for very small devices, like older or very small mobiles devices.
 */
export const useMediaXS = () => useMediaQuery("(max-width: 575px)");

/**
 * Custom hook as shortcut to check for small devices, like small mobile devices.
 */
export const useMediaSM = () => useMediaQuery("(min-width: 576px)");

/**
 * Custom hook as shortcut to check for medium-sized devices, like mobile devices.
 */
export const useMediaMD = () => useMediaQuery("(min-width: 768px)");

/**
 * Custom hook as shortcut to check for large devices, like tablets etc.
 */
export const useMediaLG = () => useMediaQuery("(min-width: 992px)");

/**
 * Custom hook as shortcut to check for very large devices, like retina tablets etc.
 */
export const useMediaXL = () => useMediaQuery("(min-width: 1200px)");

/**
 * Custom hook as shortcut to check for very large devices, like desktops etc.
 */
export const useMediaXXL = () => useMediaQuery("(min-width: 1400px)");
