import { FC } from "react";
import { KolLink } from "@public-ui/react";
import { footerRoutes } from "../../router/footerRoutes.ts";
import "./FooterLinks.scss";
import { useRoutedKolibriLinks } from "@/lib/kolibri/utils/kolibri.ts";

export const FooterLinks: FC = () => {
    const footerLink = useRoutedKolibriLinks(footerRoutes);
    return (
        <ul className="footer-links">
            {footerLink.map((link) => (
                <li key={link._href}>
                    <KolLink {...link} className="footer-link" />
                </li>
            ))}
        </ul>
    );
};
