import { TRoute } from "@/core/types/TRoute.ts";
import { PermissionLevel } from "@/_apiSchema.ts";

export const headerRoutes: TRoute[] = [
    {
        path: "/homepage",
        title: "Startseite",
    },
    {
        path: "/reports/create",
        title: "Bericht erfassen",
        teaser: {
            imageUrl: "assets/images/photo/bericht-erfassen.jpg",
            actionLabel: "Bericht erfassen",
            text: "In diesem Bereich können Sie die Prüfberichtsergebnisse manuell erfassen.",
        },
    },
    {
        path: "/reports/import",
        title: "Bericht importieren",
        teaser: {
            imageUrl: "assets/images/photo/bericht-importieren.jpg",
            actionLabel: "Bericht importieren",
            text: "In diesem Bereich können Sie Berichte per Excel-Import in das System laden.",
        },
    },
    {
        path: "/reports",
        title: "Berichtsverwaltung",
        teaser: {
            imageUrl: "assets/images/photo/berichtsverwaltung.jpg",
            actionLabel: "Berichte verwalten",
            text: "In der Berichtsverwaltung finden Sie einen Überblick über Ihre Prüfberichtsergebnisse und Auswertungen.",
        },
    },
    {
        path: "/analysis",
        title: "Auswertungen",
        teaser: {
            imageUrl: "assets/images/photo/auswertungen.jpg",
            actionLabel: "Auswertungen",
            text: "Im Bereich Berichterstattung finden Sie den Überblick über den aktuellen Stand der Überwachung Ihres Bundeslandes.",
        },
    },
    {
        path: "/administration",
        title: "Administration",
        teaser: {
            imageUrl: "assets/images/photo/administration.jpg",
            actionLabel: "Administration",
            text: "Im Bereich Administration können Sie Prüfkataloge, Prüfanforderungen, Berichtszeiträume und Bewertungsskalen anlegen oder bearbeiten.",
        },
        restrictions: [PermissionLevel.ADMIN],
    },
    {
        path: "/logs",
        title: "Logs",
        teaser: {
            imageUrl: "assets/images/photo/logs.jpg",
            actionLabel: "Protokollierung",
            text: "Im Bereich Protokollierungen finden Sie Log-Einträge und Sicherheitsprotokolle.",
        },
        restrictions: [PermissionLevel.ADMIN],
    },
];

export const homeRoute = headerRoutes[0];
