import { FC } from "react";
import { KolButton } from "@public-ui/react";
import { msalInstance, performLogout } from "@/lib/auth/controller.ts";

export const LogoutButton: FC = () => {
    const account = msalInstance.getActiveAccount();
    return (
        <KolButton
            _on={{ onClick: async () => !!account && (await performLogout(account.homeAccountId, msalInstance)) }}
            _label="Account wechseln"
            _variant="secondary"
        />
    );
};
