import { isUserDTO, TUserDTO } from "@/core/api/model/user.ts";
import { GET } from "@/core/api/client.ts";

/**
 * Fetches user info data from api
 */
export async function getUser(): Promise<TUserDTO> {
    const { data } = await GET("/user/info", {});
    if (isUserDTO(data)) {
        return data;
    }
    throw new Error("Missing or invalid user info data.");
}
