import { FC, PropsWithChildren, useEffect, useReducer } from "react";
import AppContext, { AppActionTypes, appReducer } from "@/core/context/AppContext.ts";
import { useUserQuery } from "@/core/api/hooks/user.ts";

export const AppProvider: FC<PropsWithChildren> = ({ children }) => {
    const [state, dispatch] = useReducer(appReducer, {
        user: null,
    });

    const { data: user } = useUserQuery();

    useEffect(() => {
        if (!user) return;
        dispatch({
            type: AppActionTypes.SET_USER,
            payload: { user },
        });
    }, [user]);

    return (
        <>
            <AppContext.Provider value={{ state, dispatch }}>{children}</AppContext.Provider>
        </>
    );
};
