import { FC } from "react";
import { HeaderNavbar } from "../../components/HeaderNavbar/HeaderNavbar.tsx";
import { HeaderLogo } from "../../components/HeaderLogo/HeaderLogo.tsx";
import { HeaderTitle } from "../../components/HeaderTitle/HeaderTitle.tsx";
import { HeaderLogin } from "../../components/HeaderLogin/HeaderLogin.tsx";
import { useMediaQuery } from "@/core/hooks/mediaQuery.ts";

import "./BaseHeader.scss";
import { MenuButton } from "../../components/MenuButton/MenuButton.tsx";
import { HeaderWappen } from "../../components/HeaderWappen/HeaderWappen.tsx";

const EXPAND_TARGET_CONTROL_ID = "mainMenu";

interface IBaseHeaderProps {
    expanded: boolean;
    onMenuChange: (expanded: boolean) => void;
}

export const BaseHeader: FC<IBaseHeaderProps> = ({expanded, onMenuChange}) => {
    const isLargeDesktop = useMediaQuery("(min-width: 1800px)");
    const menuClasses = ["collapsable-menu"];
    if (!isLargeDesktop) menuClasses.push("is-collapse");
    if (!expanded) menuClasses.push("collapsed");

    const navbarOrientation = isLargeDesktop ? "horizontal" : "vertical";
    const openOrCloseMenu = () => onMenuChange(!expanded);
    return (
        <header>
            <div className="row align-items-end">
                <div className="col-4">
                    <HeaderLogo />
                </div>
                <div className="col-14">
                    <div className="header-navigation">
                        <div className="navigation-wrapper">
                            <div
                                className="d-flex justify-content-end justify-content-md-between align-items-end gap-4">
                                <div className="d-md-block d-none flex-grow-1">
                                    <HeaderTitle />
                                </div>
                                {!isLargeDesktop && (
                                    <div className="py-3">
                                        <HeaderWappen />
                                    </div>
                                )}
                                <div id={EXPAND_TARGET_CONTROL_ID} className={menuClasses.join(" ")}>
                                    <div className="menu-outer-wrapper">
                                        {!isLargeDesktop && (
                                            <div className="menu-button">
                                                <MenuButton
                                                    label="Menü ausklappen"
                                                    closeLabel="Menü schließen"
                                                    expanded={expanded}
                                                    onClick={openOrCloseMenu}
                                                    expandControl={EXPAND_TARGET_CONTROL_ID}
                                                />
                                            </div>
                                        )}

                                        <div className="menu-inner-wrapper">
                                            <div className="row">
                                                <div className="col-18">
                                                    <div className={`navbar-wrapper ${navbarOrientation}`}>
                                                        <HeaderNavbar orientation={navbarOrientation}
                                                                      onNavigation={() => onMenuChange(false)} />
                                                    </div>
                                                </div>
                                                <div className="col-18 switch-account">
                                                    <div className="d-flex align-items-center gap-3">
                                                        {isLargeDesktop && <HeaderWappen />}
                                                        <HeaderLogin
                                                            orientation={isLargeDesktop ? "horizontal" : "vertical"}
                                                            align={isLargeDesktop ? "right" : "left"}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
};
