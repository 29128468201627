import "./PageLayout.css";
import { FC, useRef, useState } from "react";
import { BaseFooter } from "@/features/footer/layout/BaseFooter/BaseFooter.tsx";
import { BaseHeader } from "@/features/header/layout/BaseHeader/BaseHeader.tsx";
import { Outlet } from "react-router-dom";
import useDynamicTitle from "@/core/hooks/useDynamicTitle.ts";

export const PageLayout: FC = () => {
    useDynamicTitle();

    const [menuExpanded, setMenuExpanded] = useState(false);
    const contentRef = useRef<HTMLDivElement>(null);
    const footerRef = useRef<HTMLDivElement>(null);
    const onMenuChange = (menuExpanded) => {
        //set inert attribute on containers, so this part of the HTML document cannot be navigated via keyboard shortcuts
        if (menuExpanded){
            contentRef.current?.setAttribute("inert", "");
            footerRef.current?.setAttribute("inert", "");
        } else {
            contentRef.current?.removeAttribute("inert");
            footerRef.current?.removeAttribute("inert");
        }
        setMenuExpanded(menuExpanded);
    };

    return (
        <div className="page-wrapper">
            <div className="header-wrapper">
                <BaseHeader expanded={menuExpanded} onMenuChange={onMenuChange} />
            </div>
            <div className="content-wrapper" ref={contentRef}>
                <Outlet />
            </div>
            <div className="footer-wrapper" ref={footerRef}>
                <BaseFooter />
            </div>
        </div>
    );
};
