import { warnOnFalse } from "@/core/utils/log.ts";
import { isObject } from "@/core/utils/types.ts";

export interface INestedObject<T> {
    [key: string]: T | INestedObject<T>;
}

export type TMappingFunction<T, R = T> = (v: T) => R;

type TTypeCheckFunction<T> = (object: T) => boolean;

interface ITypeCheckOptions<T> {
    some?: (keyof T)[];
    allOf?: (keyof T)[];
    fn?: TTypeCheckFunction<T>;
    message?: string;
}

/**
 * Returns `true` is given object is matching options
 */
export function isObjectOf<T>(object: unknown, options: ITypeCheckOptions<T> = {}): object is T {
    return warnOnFalse(() => {
        if (!object || typeof object !== "object") return false;
        const keys = Object.keys(object);
        if (options.some && !keys.some((key) => object[key] !== undefined)) return false;
        if (options.allOf && !keys.every((key) => object[key] !== undefined)) return false;
        return !(options.fn && !options.fn(object as T));
    }, options.message);
}

/**
 * Returns `true` if given object is empty
 */
export function isEmptyObject(object: object) {
    return object && Object.keys(object).length === 0 && object.constructor === Object;
}

/**
 * Returns a clone of given value (not deeply)
 */
export function clone<T = unknown>(value: T) {
    if (value === null || value === undefined) return value;
    if (isObject(value)) return { ...value };
    if (Array.isArray(value)) return [...value] as T;
    return value;
}

/**
 * Filters tree data with repeating structure
 */
export function filterTree<T extends Partial<{ [K in ChildProperty]?: T[] }>, ChildProperty extends string>(
    data: T[],
    childProp: ChildProperty,
    filterFn: (d: T) => boolean,
): T[] {
    const out = [] as T[];
    data.forEach((item) => {
        if (!filterFn(item)) {
            return;
        }
        const clone = { ...item } as T;
        if (Object.prototype.hasOwnProperty.call(clone, childProp)) {
            clone[childProp] = filterTree(clone[childProp] as T[], childProp, filterFn) as T[ChildProperty];
        }
        out.push(clone);
    });
    return out;
}

/**
 * Returns first item in tree structure
 */
export function findInTree<T extends Partial<{ [K in ChildProperty]?: T[] }>, ChildProperty extends string>(
    data: T[],
    childProp: ChildProperty,
    filterFn: (d: T) => boolean,
): T | null {
    if (!Array.isArray(data)) {
        return null;
    }
    for (const item of data) {
        if (filterFn(item)) {
            return item;
        }
        if (Object.prototype.hasOwnProperty.call(item, childProp)) {
            return findInTree(item[childProp] as T[], childProp, filterFn);
        }
    }
    return null;
}

/**
 * Build an object from given path
 */
function fillObjectFromPath<T = unknown>(obj: INestedObject<T>, value: T, path: string[]) {
    const key = path.shift();
    if (!key) {
        return;
    }
    if (path.length === 0) {
        obj[key] = value;
        return;
    }
    obj[key] = {};
    fillObjectFromPath(obj[key] as INestedObject<T>, value, path);
}

/**
 * Builds a nested object from object with dot notation keys
 */
export function dotNotationToNestedObject<T = unknown>(object: Record<string, T>): INestedObject<T> {
    const result: INestedObject<T> = {};
    Object.keys(object).forEach((path) => fillObjectFromPath<T>(result, object[path], path.split(".")));
    return result;
}

/**
 * Converts and deeply nested object to one dimensional object with keys written in dot notation
 */
export function nestedObjectToDotNotation<T = unknown, R = T>(
    object: INestedObject<T>,
    mapFn: TMappingFunction<T, R> | null = null,
    prefix = "",
): Record<string, T | R> {
    const result: Record<string, T | R> = {};
    Object.keys(object).forEach((key) => {
        const newPrefix = prefix ? `${prefix}.${key}` : key;
        const value = object[key];
        if (isObject(value)) {
            Object.assign(result, nestedObjectToDotNotation(object[key] as INestedObject<T>, mapFn, newPrefix));
        } else {
            result[newPrefix] = mapFn ? mapFn(value) : value;
        }
    });
    return result;
}
