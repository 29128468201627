enum LogLevel {
    DEBUG = 1,
    INFO = 2,
    WARNING = 3,
    ERROR = 4,
}

const level = parseInt(import.meta.env.VITE_LOG_LEVEL) || LogLevel.ERROR;

/**
 * Logs a warning depending on current log level
 */
export function warn(...message: unknown[]) {
    if (level > LogLevel.WARNING) {
        return;
    }
    console.warn(...message);
}

/**
 * Logs message as warning on false result. Returns result for chaining.
 */
export function warnOnFalse(result: boolean | (() => boolean), message: string | undefined): boolean {
    const finalResult = typeof result === "function" ? result() : result;
    if (!finalResult && message) warn(message);
    return finalResult;
}
