import { createContext, Dispatch, useContext } from "react";
import { IUser } from "@/core/api/model/user.ts";

export enum AppActionTypes {
    SET_USER = "SET_USER",
}

interface IAppState {
    /**
     * User info object
     */
    user: IUser | null;
}

interface IAppActions {
    type: AppActionTypes.SET_USER;
    payload: { user: IAppState["user"] };
}

interface IAppContext {
    state: IAppState;
    dispatch: Dispatch<IAppActions>;
}

const AppContext = createContext<IAppContext>({
    state: {
        user: null,
    },
    dispatch: () => null,
});
export default AppContext;

export function appReducer(state: IAppState, action: IAppActions): IAppState {
    switch (action.type) {
        case AppActionTypes.SET_USER:
            return {
                ...state,
                user: action.payload.user,
            };

        default:
            return state;
    }
}

/**
 * Custom hook for user
 */
export function useUser(): IUser | null {
    const { state } = useContext(AppContext);
    return state.user;
}
